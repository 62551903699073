import React from 'react';
import { string } from 'prop-types';
import loadable from '@loadable/component';

import {
  LOYALTY_SHARE_ROUTE, LOYALTY_QR_ROUTE,
  LOYALTY_ROUTE, LOYALTY_SEARCH_ROUTE,
} from 'constants/navigation';
import {
  SHARE, REWARD,
  SCAN, SEARCH,
} from 'constants/font-awesome';

import useStyles from '../styles';

const LazyBanner = loadable(() => import('components/banners/in-app-banners/three'));
const Page = loadable(() => import('components/page'));
const LazyLoyaltySearchView = loadable(() => import('views/loyalty-card/loyalty/search'));
const LazyOverrideFooter = loadable(() => import('components/footer/override-footer'));
const Typography = loadable(() => import('@material-ui/core/Typography'));

const SuspendedSearchForId = ({ passId = '', title = '' }) => {
  const styles = useStyles();

  return (
      <Page
        title="Search Loyalty Id"
        description={'Search for a Loyalty Card and add stamps or redeem rewards.'}>
        <LazyBanner/>
        <Typography variant="h2" component="h1" className={styles.heading} align="center">
          {'Stamp and Reward'}
        </Typography>
        <Typography variant="h4" component="p" className={styles.headingMessage} align="center">
         {'Search for a Loyalty Card by the Loyalty Id'}
        </Typography>
        <div className={styles.content}>
          <LazyLoyaltySearchView passId={passId} title={title}/>
        </div>
        <LazyOverrideFooter
          navItems={[
            {
              route: `${LOYALTY_SHARE_ROUTE}?passId=${passId}`,
              icon: SHARE,
              message: 'Share',
            },
            { route: `${LOYALTY_ROUTE}?passId=${passId}`, icon: REWARD, message: 'Loyalty' },
            { route: `${LOYALTY_QR_ROUTE}?passId=${passId}`, icon: SCAN, message: 'QR' },
            { route: `${LOYALTY_SEARCH_ROUTE}?passId=${passId}`, icon: SEARCH, message: 'Search' },
          ]}
          currentItem="Search"
          />
      </Page>
  );
};

SuspendedSearchForId.propTypes = {
  passId: string,
  title: string,
};

export default SuspendedSearchForId;
